.subscription-page {
    margin: 0 auto;
    width: 100%;
    min-height: 65vh;
    
    @include solid-breakpoint("md") {
        min-height: 100vh;
    }    
}

.subscription-page__container {
    max-width: 75rem;
    margin: 0 auto;
    padding: 2rem 1.25rem;
    
    @include solid-breakpoint("md") {
        padding: 3.5rem .5rem;
    }
}

.button--manage-subscription {
    border-radius: 4px;
    margin-top:.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 45px;
    font-weight: 800;
    width: 100%;
}

.subscription-header-container {
    display: flex;
    flex-direction: column;
    max-width: 482px;
}

.subscription-header {
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 1rem;
    
    @include solid-breakpoint("md") {
        line-height: 49px;
        font-size: 40px;
    }
}

.subscription-copy {
    font-size: 14px;
    color: #222222;
    line-height: 17px;;
    margin-bottom: 1.5rem;
}

.subscription-email-container {
    width: 100%;
    margin-top: 1rem;

    @include solid-breakpoint("md") {
        width: 482px;
    }
}