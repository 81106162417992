.newsletter-form-container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    justify-content: space-between;

    @include solid-breakpoint('md') {
        flex-direction: row;
        justify-content: normal;
    }
}

.preference-list-container {
    margin-right: 0;

    @include solid-breakpoint('md') {
        margin-right: 2.5rem;
        border-right: 2px solid #F4F4F4;
        margin-bottom: 2rem;
    }
}

.preference-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    max-width: 532px;
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-right: .5rem;
    cursor: pointer;
}

.preference-checkbox {
    -webkit-appearance: checkbox;
    width: 27px;
    height: 27px;
    margin-right: 1rem;
    border: 2px;
    border-radius: 4px;
    border: 2px solid #F4F4F4;
    cursor: pointer;
}

.preference-header {
    font-size: 18px;
    font-weight: 800;
}

.preference-subheader {
    font-size: 14px;
    color: #757575;
}

.preference-description {
    font-size: 14px;

}

.email-form-container {
    flex-direction: column;
    width: 100%;
    border-top: 2px solid #F4F4F4;
    padding-top: 1.5rem;
    display: none;

    @include solid-breakpoint('md') {
        max-width: 305px;
        border: none;
        display: flex;
    }
}

.email-form-label {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: .75rem;
}

.email-form-description {
    font-size: 12px;
    line-break: 14.5px;
    color: #757575;
    margin-top: .5rem;
    margin-bottom: 1rem;
}

.email-form-input {
    border-radius: 4px;
    border: 2px solid #F4F4F4;
    padding: 13px 12px 13px 12px;
    width: 100%;
    margin-bottom: .25rem;
}

.button--sign-up {
    margin: 0;
    radius: 4px;
}