.newsletter-page {
    margin: 0 auto;
    width: 100%;
    min-height: 100vh;

    @include solid-breakpoint('md') {
        min-height: 100vh;
    }
}

.newsletter-page__container {
    max-width: 75rem;
    margin: 0 auto;
    padding: 2rem 1.25rem;
    
    @include solid-breakpoint('md') {
        padding: 3.5rem .5rem;
    }
}

.button--subscription {
    @include solid-breakpoint('md') {
        border-radius: 4px;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: .5rem;
        padding-right: .5rem;
        margin-top:.25rem;
        height: 45px;
        width: 190px;
    }

    padding-top: .25rem;
    width: 88px;
    height: 35px;

}

.newsletter-header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;

    @include solid-breakpoint('md') {
        justify-content: normal;
    }
}

.newsletters-header {
    @include solid-breakpoint('sm') {
        font-size: 40px;
        line-height: 49px;
        margin-bottom: 1rem;
        margin-right: 2rem;
    }
    
    font-weight: 800;
    font-size: 32px;
}

.newsletter-copy {
    @include solid-breakpoint('sm') {
        font-size: 19px;
        margin-bottom: 2.5rem;
    }
    font-size: 16px;
    color: #222222;
    margin-bottom: .5rem;
}

.email-form-container-sticky {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 2px solid #F4F4F4;
    padding-top: 1.5rem;

    @include solid-breakpoint('md') {
        max-width: 305px;
        border: none;
        display: none;
    }
}

.sticky-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    z-index: 1000;
    padding: 1rem 1.25rem;
    opacity: 1;

    transition: opacity 0.5s ease-in;
    
    @include solid-breakpoint('md') {
        display: none;
    }
}

.hide-sticky {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}