.toast-container {
  
  
  svg {
    margin-left: 0.25rem;
    margin-right: 0.5rem;
    margin-top: 0.25rem;
  }
  
  @include solid-breakpoint('md') {
    min-width: 345px;
    font-size: 16px;
    text-wrap: nowrap;
    margin-top: .25rem;
  }

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    transform: translateX(-50%);
    top: 115px;
    left: 50%;
    display: flex;
    min-width: 90vw;
    min-height: 55px;
    padding: 16px 12px;
    font-size: 16px;
    font-weight: 700;
    background-color: $white;
    border: 2px solid #CCE5B3;
    border-radius: 100px;
    color: #618910;
    z-index: 100000;
    text-align: center;

    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.show {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }

  }

  .toast-success {
    color: #618910;
    border: 2px solid #618910;
  }

  .toast-error {
    color: #B71A22;
    border: 2px solid #B71A22;
  }